<template>
  <div class="row mt-4 goal-wrapper">
    <p class="col-lg-12 text-purple font-weight-bold">Additional Room Goals:</p>
    <div class="goal-list col-lg-12">
      <div
        class="goal-item mb-1 w-100 row"
        v-for="(goal, index) in goalsList"
        :key="index"
      >
        <label :for="'roomGoal' + (index + 1)" class="col-md-6 col-lg-6">
          <span>{{ goal.propertyName }}:</span>
          <span
            v-if="showText && isFollowUpAssessment"
            :class="{
              'text-danger': goal.propertyValue !== 'n/a' && Number(goal.propertyValue) <= 4,
              'text-warning': goal.propertyValue !== 'n/a' && Number(goal.propertyValue) > 4 && Number(goal.propertyValue) <= 7,
              'text-success': goal.propertyValue !== 'n/a' && Number(goal.propertyValue) > 7
            }"
          >
            ({{ goal.propertyValue }})
          </span>
        </label>
        <div class="control-wrapper col-sm-12 col-lg-6">
          <div class="row w-100">
            <div class="col-sm-8">
              <input
                :id="'roomGoal' + (index + 1)"
                v-model="goal.propertyName"
                class="form-control"
                type="text"
                @input="emitGoalsList()"
              />
            </div>
            <select
              v-if="isFollowUpAssessment"
              class="form-control col-md-3"
              v-model="goal.propertyPostValue"
              :disabled="!goal.propertyName.length"
              @change="emitGoalsList()"
            >
              <option value="n/a">N/A</option>
              <option value="0">0</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <select
              v-else
              class="form-control col-md-3"
              v-model="goal.propertyValue"
              :disabled="!goal.propertyName.length"
              @change="emitGoalsList()"
            >
              <option value="n/a">N/A</option>
              <option value="0">0</option>
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        <button
          @click="deleteGoal(index)"
          v-if="goalsList.length > 0"
          class="del-button ml-2 btn purple-btn btn-shadow font-weight-bold"
        >
          <FontAwesomeIcon icon="minus" />
        </button>
      </div>
      <div class="manage-goals">
        <button
          @click="addGoal"
          class="btn purple-btn btn-shadow mb-2 font-weight-bold"
        >
          <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Add Goal
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    goalsList: {
      type: Array,
      required: true,
      default: () => [],
    },
    showText: {
      type: Boolean,
      default: false,
    },
    roomStatus: {
      type: String,
      default: '',
    },
    roomId: {
      type: String,
      default: '',
    },
  },
  computed: {
    isFollowUpAssessment() {
      return (
        this.roomStatus === 'Follow-Up Assessment Started' ||
        this.roomStatus === 'Follow-Up Assessment Completed'
      );
    },
  },
  methods: {
    addGoal() {
      const newGoal = {
        id: 0,
        propertyName: `Room Goal ${this.goalsList.length + 1}`,
        propertyValue: "n/a",
        propertyPostValue: "n/a",
        roomId: this.roomId || null,
      };

      this.goalsList.push(newGoal);
      this.emitGoalsList();
    },
    deleteGoal(index) {
      this.goalsList.splice(index, 1);
      this.emitGoalsList();
    },
    emitGoalsList() {
    this.$emit("goals-updated", this.goalsList);
    },
  },
};
</script>

<style>
.goal-wrapper {
  flex-direction: column;
}

.goal-item {
  display: flex;
  align-items: center;
  position: relative;
}

.control-wrapper {
  display: flex;
  align-items: center;
}

.del-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>